import React, { useState, useEffect, useContext } from "react"
import { linkResolver } from "utils/linkResolver"
import { graphql, navigate } from "gatsby"
import Cookies from "js-cookie"
import styled from "styled-components"
import Checkbox from "lib/Checkbox"
import Icon from "lib/Icon"
import Content from "components/Content"
import {
  ButtonGroup,
  Textarea,
  Form,
  FormContext,
  FormField,
  Button,
  ValidationInput,
} from "@clevertrack/shared"
import { createHubspotLeadModel, addLeadToHubspot } from "./service"
import Accordion from "lib/Accordion"
import tw from "twin.macro"

const StyledHubspotLeadForm = styled(Form)`
  padding: 2rem 0 0;
  position: relative;

  .consent {
    margin: 2rem 0;
    align-items: center;
    p {
      margin-top: -0.8rem;
      ${tw`text-lg md:text-xl`}

      ${(props) => props.theme.media.tablet_landscape_up`
        margin-top: -0.4rem;
      `}
    }

    &.-missing {
      color: ${(props) => props.theme.colors.danger};
    }
  }

  .toggle-terms-link {
    cursor: pointer;
    ${tw`text-brand-500 mb-8 text-right text-lg md:text-xl`}
    // float: right;
    display: block;
    align-items: center;

    svg {
      width: 1rem;
      height: 1rem;
      margin-left: 0.5rem;
      transition: ease-out 150ms all;
      transform: rotateZ(0);
    }

    &.-toggled {
      svg {
        transform: rotateZ(-180deg);
      }
    }
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    margin: 0 auto 4rem;

    .wrapper {
      position: relative;
      z-index: 10;
      background: white;
      padding: 2rem;
      filter: drop-shadow(rgba(50, 50, 93, 0.5) 20px 20px 40px);
      max-width: 60rem;
      margin: 0 auto;
    }
  `}
`

const StyledResult = styled.article`
  font-family: ${(props) => props.theme.fontStacks.light};

  &.success {
    text-align: center;
    svg {
      fill: ${(props) => props.theme.colors.success};
    }
  }

  &.error {
    svg {
      fill: ${(props) => props.theme.colors.danger};
    }
  }
`

const StyledHubspotLeadFormFields = styled.article`
  padding: 0 2rem;

  ${(props) => props.theme.media.tablet_landscape_up`
    padding: 0;
  `}
`

const StyledLegalContent = styled.article`
  margin: 2rem 2rem 0;
  padding: 1px 2rem 0;
  background: ${(props) => props.theme.colors.grayLighter};
  text-align: left;

  ${(props) => props.theme.media.tablet_landscape_up`
    margin: 2rem auto 0;
  `}

  p {
    font-size: 1.4rem;
    line-height: 1.4;

    &:first-of-type {
      margin-top: 0;
    }
  }
`

function HubspotLeadModuleForm({ onValidate, onChange, ...props }) {
  const {
    state: { fields },
  } = useContext(FormContext)

  useEffect(() => {
    onValidate(fields)
  }, [fields])

  return (
    <StyledHubspotLeadFormFields>
      <FormField label="Email" validationKey="email">
        <ValidationInput
          id="email"
          type="email"
          placeholder="Indtast email adresse"
          onChange={(e) => onChange("email", e.target.value)}
          required
        />
      </FormField>
      <FormField label="Navn" validationKey="firstname">
        <ValidationInput
          id="firstname"
          type="text"
          placeholder="Indtast dit fornavn"
          onChange={(e) => onChange("firstname", e.target.value)}
          required
        />
      </FormField>
      <FormField label="Virksomhed" validationKey="company">
        <ValidationInput
          id="company"
          type="text"
          placeholder="Indtast virksomhedens navn"
          onChange={(e) => onChange("company", e.target.value)}
          required
        />
      </FormField>
      <FormField label="Telefon" validationKey="phone">
        <ValidationInput
          id="phone"
          type="phone"
          placeholder="Indtast telefonnummer"
          onChange={(e) => onChange("phone", e.target.value)}
          required
        />
      </FormField>
      <FormField label="Besked (frivillig)">
        <Textarea
          id="message"
          placeholder="Fortæl os lidt om dine behov"
          onChange={(e) => onChange("message", e.target.value)}
        />
      </FormField>
    </StyledHubspotLeadFormFields>
  )
}

const HubspotLeadModule = ({
  hs_form_id,
  hs_portal_id,
  hs_retry_btn_text,
  hs_submit_btn_text,
  hs_success_message,
  hs_error_message,
  confirmation_page,
  meta,
  use_theme,
  theme,
  ...props
}) => {
  const context = {
    hutk: Cookies.get("hubspotutk"),
    pageUri: `${meta.host}${meta.uri}`,
    pageName: `${meta.title}`,
  }
  const [model, setModel] = useState({
    email: "",
    firstname: "",
    company: "",
    phone: "",
    message: "",
  })
  const [invalidFields, setInvalidFields] = useState([])
  const [consentCommunication, setConsentCommunication] = useState(false)
  const [consentProcess, setConsentProcess] = useState(false)
  const [toggleTerms, setToggleTerms] = useState(false)
  const [result, setResult] = useState(null)

  function onValidateHandler(fields) {
    setInvalidFields(fields)
  }

  function onChangeHandler(name, value) {
    const newModel = {
      ...model,
      [name]: value,
    }
    setModel(newModel)
  }

  async function onSubmitHandler(e) {
    e.preventDefault()
    if (typeof window !== "undefined" && window.grecaptcha) {
      window.grecaptcha.enterprise.ready(async () => {
        const token = await window.grecaptcha.enterprise.execute(
          `${process.env.GATSBY_RECAPTCHA_ENTERPRISE_SITE_KEY}`,
          { action: "submit" }
        )

        if (token) {
          const hubspotFields = []
          Object.keys(model).forEach((value) => {
            hubspotFields.push({
              name: value,
              value: model[value],
            })
          })
          const submissionModel = createHubspotLeadModel(
            hubspotFields,
            context,
            consentProcess,
            consentCommunication
          )
          const r = await addLeadToHubspot(
            hs_portal_id,
            hs_form_id,
            submissionModel
          )
          if (r.status === 200) {
            setResult("success")
            setTimeout(() => {
              navigate(linkResolver(confirmation_page.document))
            }, 3000)
          } else setResult("error")
        }
      })
    }
  }

  function onTermsToggleHandler(e) {
    e.stopPropagation()
    e.preventDefault()
    setToggleTerms((prev) => !prev)
  }

  return (
    <StyledHubspotLeadForm
      useTheme={use_theme}
      colorTheme={theme}
      onSubmit={onSubmitHandler}
    >
      <div className="wrapper">
        {result !== null ? (
          result === "error" ? (
            <StyledResult className="error">
              <Icon icon="times-circle" size="xl" />
              <Content content={hs_error_message.richText} />
              <Button onClick={() => setResult(null)}>
                {hs_retry_btn_text}
              </Button>
            </StyledResult>
          ) : (
            <StyledResult className="success">
              <Icon icon="check-circle" size="xl" />
              <Content content={hs_success_message.richText} />
            </StyledResult>
          )
        ) : (
          <>
            <HubspotLeadModuleForm
              onChange={onChangeHandler}
              onValidate={onValidateHandler}
              {...props}
            />
            <StyledLegalContent>
              <Checkbox
                onChange={(value) => setConsentCommunication(value)}
                checked={consentCommunication}
                className={`consent`}
              >
                <p>
                  Jeg samtykker til at modtage kommunikation fra Clevertrack
                  ApS.
                </p>
              </Checkbox>
              <Checkbox
                onChange={(value) => setConsentProcess(value)}
                checked={consentProcess}
                required
                className={`consent${consentProcess ? "" : " -missing"}`}
              >
                <p>
                  Jeg samtykker til at Clevertrack ApS må opbevare og behandle
                  mine personlige data. *
                </p>
              </Checkbox>
              <a
                href
                className={`toggle-terms-link${toggleTerms ? " -toggled" : ""}`}
                onClick={onTermsToggleHandler}
              >
                Læs vores betingelser her
                <Icon icon="chevron-down" size="auto" />
              </a>
              <Accordion toggled={toggleTerms}>
                <div tw="text-lg pb-4">
                  <p>
                    <small>
                      Clevertrack ApS er forpligtet til at beskytte og
                      respektere dit privatliv, og vi anvender kun dine
                      personlige oplysninger til at administrere dit engagement
                      med os og yde de services du har forespurgt fra os. Fra
                      tid til anden vil vi gerne kontakte dig med information om
                      vores services, så vel som andet indhold der kan være
                      interessant for dig. Hvis du giver samtykke til at vi
                      kontakter dig med dette formål, afkryds venligst "Jeg
                      samtykker til at modtage kommunikation fra Clevertrack
                      ApS." herover.
                    </small>
                  </p>
                  <p>
                    <small>
                      For at kunne tilbyde dig det forespurgte indhold, skal vi
                      opbevare og behandle dine personlige data. Hvis du
                      samtykker til at vi opbevarer dine data til dette formål,
                      afkryds venligst samtykke-feltet herover.
                    </small>
                  </p>
                  <p>
                    <small>
                      Du kan til enhver tid afmelde dig vores kommunikation. For
                      mere information om hvordan du afmelder dig, vores
                      praksiser i forbindelse med anonymitet og hvordan vi er
                      forpligtede til at beskytte og respektere dit privatliv,
                      se da vores privatlivspolitik.
                    </small>
                  </p>
                </div>
              </Accordion>
            </StyledLegalContent>
            <ButtonGroup position="center">
              <Button variant="primary" disabled={!consentProcess}>
                {hs_submit_btn_text}
              </Button>
            </ButtonGroup>
          </>
        )}
      </div>
    </StyledHubspotLeadForm>
  )
}

export default HubspotLeadModule

HubspotLeadModule.defaultProps = {}
HubspotLeadModule.propTypes = {}

export const query = graphql`
  fragment HubspotModuleFragment on PrismicLeadModuleDataBodyHubspot {
    slice_type
    primary {
      hs_form_id
      hs_portal_id
      hs_retry_btn_text
      hs_submit_btn_text
      hs_success_message {
        richText
        text
      }
      hs_error_message {
        richText
        text
      }
      confirmation_page {
        document {
          ... on PrismicPage {
            uid
            type
            lang
          }
        }
      }
    }
  }
`
